/**
 * @package		Al hirafiyin web app
 * @desc		Page script 
 *
 * @copyright	Copyright (C) 2022 Jaayfer abderrahim, Inc. All rights reserved.
 * @license		see LICENSE.txt
 */
import $ from 'jquery';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import app from '../../app';
import 'photoswipe/style.css';
class PhotoBrowser {
  /**
   * Constructor
   */
  constructor() {
    this.browsers = {};

    // get message from an iframe
    window.addEventListener('message', event => {
      const isRTL = $('html').attr('dir') === 'rtl';
      if (typeof event.data == 'object' && event.data.type == 'get-photos-browser') {
        this.browsers[event.data.photos.id] = this.initialize(isRTL ? event.data.photos.source.reverse() : event.data.photos.source, event.data.options);
        event.stopPropagation();
      }
      if (typeof event.data == 'object' && event.data.type == 'open-photos-browser') {
        const instance = this.browsers[event.data.photos.id];
        const total = instance.options.dataSource.length;
        instance.loadAndOpen(isRTL ? total - 1 - event.data.photos.photo : event.data.photos.photo);
        event.stopPropagation();
      }
    });
  }

  /**
   * Initialize photo browser module
   * 
   * @return	{PhotoSwipeLightbox}
   */
  initialize(photosSource, options) {
    const self = this;
    const isRTL = $('html').attr('dir') === 'rtl';
    const type = typeof photosSource == 'string' ? 'element' : Array.isArray(photosSource) ? 'list' : null;
    if (typeof options != 'object' || Array.isArray(options)) {
      options = {};
    }
    let params;
    switch (type) {
      case 'element':
        const instances = [];
        $(photosSource).each(function () {
          const images = [];
          const total = $(this).find('a').length;
          $(this).find('a').each(function (idx) {
            images.push({
              src: $(this).attr('href'),
              width: $(this).attr('data-pswp-width'),
              height: $(this).attr('data-pswp-height'),
              alt: $(this).attr('alt') || undefined
            });
            $(this).on('click', function () {
              $(this).closest(photosSource).data('photosBrowser').loadAndOpen(isRTL ? total - 1 - idx : idx);
            });
          });
          const instance = self.initialize(isRTL ? images.reverse() : images);
          $(this).data('photosBrowser', instance);
          instances.push($(this).data('photosBrowser'));
        });
        return instances;
      case 'list':
        params = {
          dataSource: photosSource,
          history: true,
          preloaderDelay: 15000,
          showHideAnimationType: 'none',
          loop: false,
          pswpModule: () => import('photoswipe')
        };
        ;
        break;
      default:
        params = {};
        break;
    }
    const lightbox = new PhotoSwipeLightbox(params);
    lightbox.addFilter('itemData', (itemData, index) => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      let elHeight;
      let elWidth;
      if (width > height) {
        elHeight = elWidth = height;
      } else {
        elHeight = elWidth = width;
      }
      itemData.width = itemData.w = elWidth;
      itemData.height = itemData.h = elHeight;
      const webpSrc = itemData.element && itemData.element.dataset ? itemData.element.dataset.pswpWebpSrc : null;
      if (webpSrc) {
        itemData.webpSrc = webpSrc;
      }
      return itemData;
    });
    lightbox.init();

    // set page history
    if (!options.referralLink) {
      options.referralLink = location.href;
    }
    lightbox.on('openingAnimationStart', event => {
      const pswp = lightbox.pswp;
      lightbox.firstRun = 1;
      $('.pswp__button--close').on('close', function () {
        pswp.close();
      });
      app.history().push(new URL(pswp.currSlide.data.src).pathname, {
        close: '.pswp__button--close'
      });
    });
    lightbox.on('change', event => {
      if (!lightbox.firstRun) {
        return;
      }
      const pswp = lightbox.pswp;
      app.history().update(new URL(pswp.currSlide.data.src).pathname);
    });
    lightbox.on('close', event => {
      const pswp = lightbox.pswp;
      lightbox.firstRun = 0;
      app.history().pop(new URL(pswp.currSlide.data.src).pathname);
    });
    return lightbox;
  }
}
export default new PhotoBrowser();