/**
 * @package		Al hirafiyin web app
 * @desc		Page script 
 *
 * @copyright	Copyright (C) 2022 Jaayfer abderrahim, Inc. All rights reserved.
 * @license		see LICENSE.txt
 */
export default {
  foundClosestWords(phrase, words, sensitivity) {
    /**
     * Declare the necessary variables
     */
    var minSure = sensitivity && sensitivity >= 0 && sensitivity <= 1 ? 1 - sensitivity : 0.4;
    var similarWords = new Array();
    if (!phrase) return similarWords;
    var strings = phrase.split(' ');

    /**
     * Perform the jaro-winkler algorithm on the 2 inputs
     *
     * @function
     * @param {String} str1 The first string to compare
     * @param {String} str2 The Second string to compare
     */
    function jaroWinkler(s1, s2, options) {
      function extend(a, b) {
        for (var property in b) {
          if (b.hasOwnProperty(property)) {
            a[property] = b[property];
          }
        }
        return a;
      }
      var m = 0;
      var defaults = {
        caseSensitive: true
      };
      var settings = extend(defaults, options);
      var i;
      var j;

      // Exit early if either are empty.
      if (s1.length === 0 || s2.length === 0) {
        return 0;
      }

      // Convert to upper if case-sensitive is false.
      if (!settings.caseSensitive) {
        s1 = s1.toUpperCase();
        s2 = s2.toUpperCase();
      }

      // Exit early if they're an exact match.
      if (s1 === s2) {
        return 1;
      }
      var range = Math.floor(Math.max(s1.length, s2.length) / 2) - 1;
      var s1Matches = new Array(s1.length);
      var s2Matches = new Array(s2.length);
      for (i = 0; i < s1.length; i++) {
        var low = i >= range ? i - range : 0;
        var high = i + range <= s2.length - 1 ? i + range : s2.length - 1;
        for (j = low; j <= high; j++) {
          if (s1Matches[i] !== true && s2Matches[j] !== true && s1[i] === s2[j]) {
            ++m;
            s1Matches[i] = s2Matches[j] = true;
            break;
          }
        }
      }

      // Exit early if no matches were found.
      if (m === 0) {
        return 0;
      }

      // Count the transpositions.
      var k = 0;
      var numTrans = 0;
      for (i = 0; i < s1.length; i++) {
        if (s1Matches[i] === true) {
          for (j = k; j < s2.length; j++) {
            if (s2Matches[j] === true) {
              k = j + 1;
              break;
            }
          }
          if (s1[i] !== s2[j]) {
            ++numTrans;
          }
        }
      }
      var weight = (m / s1.length + m / s2.length + (m - numTrans / 2) / m) / 3;
      var l = 0;
      var p = 0.1;
      if (weight > 0.7) {
        while (s1[l] === s2[l] && l < 4) {
          ++l;
        }
        weight = weight + l * p * (1 - weight);
      }
      return weight;
    }

    /**
     * Checker
     */
    const swears = [];
    (Array.isArray(words) ? words : []).forEach(word => {
      const segs = word.split(' ');
      segs.forEach(function (seg) {
        swears.push(seg);
      });
    });
    for (let i = 0; i < strings.length; i++) {
      let word = strings[i];
      word = word.replace(/\b[-.,()&$#:!\[\]{}"']+\B|\B[-.,()&$#:!\[\]{}"']+\b/g, "");
      for (let j = 0; j < swears.length; j++) {
        let swear = swears[j].substr(0, word.length);
        const sureness = Math.round(jaroWinkler(swear, word, {
          caseSensitive: false
        }) * 100) / 100;
        if (sureness >= minSure) {
          similarWords.push({
            word: swear,
            sureness: sureness
          });
        }
      }
    }
    return similarWords;
  },
  hashString(str) {
    str = String(str);
    var hash = 0,
      i,
      chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }

    return hash;
  }
};