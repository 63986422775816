/**
 * @package		Al hirafiyin web app
 * @desc		Page script 
 *
 * @copyright	Copyright (C) 2022 Jaayfer abderrahim, Inc. All rights reserved.
 * @license		see LICENSE.txt
 */
import $ from 'jquery';
import '../../commons/css/components/craftsman-tools.css';
import app from '../../app';
class craftsmanTools {
  /**
   * Constructor
   */
  constructor() {
    // get message from an iframe
    window.addEventListener('message', event => {
      if (typeof event.data == 'object' && event.data.type == 'like-craftsman-profile') {
        this.likeProfile(event.data.user.id, event.data.status);
        event.stopPropagation();
      }
      if (typeof event.data == 'object' && event.data.type == 'block-craftsman-profile') {
        this.blockProfile(event.data.user.id);
        event.stopPropagation();
      }
      if (typeof event.data == 'object' && event.data.type == 'unblock-craftsman-profile') {
        this.unblockProfile(event.data.user.id);
        event.stopPropagation();
      }
      if (typeof event.data == 'object' && event.data.type == 'craftsman-profile-additional-actions-sheet') {
        this.getAdditionalActionsSheet(event.data.user.id, event.data.user.link);
        event.stopPropagation();
      }
      if (typeof event.data == 'object' && event.data.type == 'open-link-social-network-craftsman-profile') {
        this.openSocialMediaLink(event.data.link);
        event.stopPropagation();
      }
    });
  }

  /**
   * Get profile
   * 
   * @return {void}
   */
  openProfile(userId) {
    const self = this;
    const closeBtn = '#craftsman-profile-container .sheet-close';
    const width = window.innerWidth;
    const height = window.innerHeight;
    let link;
    let orgLink;
    if (/^http:|^https/.test(userId)) {
      orgLink = userId;
      const _link = new URL(orgLink);
      _link.searchParams.set('iframe', 1);
      userId = _link.pathname.match(/\/\d+(?=\/|$)/)[0].substring(1);
      link = _link.toString();
    } else if (String(userId).replace(/[0-9]{1,}/ig, '').length) {
      orgLink = String(userId).charAt(0) == '/' ? userId : '/' + userId;
      const _link = new URL(`https://www.locals.works${orgLink}`);
      userId = _link.pathname.match(/\/\d+(?=\/|$)/)[0].substring(1);
      _link.searchParams.set('iframe', 1);
      link = _link.toString().replace(_link.origin, '');
    } else {
      orgLink = `/${app.getUserLanguage()}/craftsmen/profiles/${userId}`;
      const _link = new URL(`https://www.locals.works${orgLink}`);
      _link.searchParams.set('iframe', 1);
      link = _link.toString().replace(_link.origin, '');
    }
    userId = parseInt(userId) || 0;
    window.onIframeCraftsmanProfileFullyLoaded = iframe => {
      $('#craftsman-profile-container .left').empty();
      $('#craftsman-profile-container .loading').addClass('animate__animated animate__slideOutDown');
      setTimeout(function () {
        $('#craftsman-profile-container .loading').remove();
      }, 500);
      $(iframe).show();
      var error = iframe.contentDocument ? iframe.contentDocument.getElementsByClassName('error-message') : [$('<span>').text(app.translate('An error occurred, please try again later')).get(0)];
      if (error.length) {
        $(closeBtn).trigger('click');
        app.ui.dialog.alert($(error[0]).text().trim());
        return;
      }
      app.trackEvent('profile_visited');
    };
    app.ui.sheet.create({
      content: `
				<div class="sheet-modal sheet-modal-center craftsman-profile-${userId} craftsman-profile-container" id="craftsman-profile-container" style="height:${Math.ceil(height * 75 / 100)}px;width:${Math.ceil(width * 75 / 100)}px;">
				<div class="toolbar">
					<div class="toolbar-inner">
						<div class="left">&nbsp;${app.translate('Loading profile ..')}</div>
						<div class="title"></div>
						<div class="right">
							<div class="link sheet-close btn-close-croftsman-profile">
								<i class="icon icon-qing"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="sheet-modal-inner">
					<div class="loading">
						<span class="loading-sign"></span>
					</div>
					<iframe src="${link}" style="width: 100%; height: 100%; border:0; display:none;" onload="onIframeCraftsmanProfileFullyLoaded(this);"></iframe>
				</div>
			</div>
			`,
      swipeToClose: false,
      backdrop: true,
      // Events
      on: {
        open: function (sheet) {
          app.history().push(orgLink, {
            close: '.craftsman-profile-container .sheet-close'
          });
        },
        close: function (sheet) {
          app.history().pop(orgLink);
        }
      }
    }).open();
  }

  /**
   * View share craftsman profile
   * @param	{Object}	params
   *
   * @return	{Promise}
   */
  shareProfile(link) {
    const self = this;
    app.ui.actions.create({
      buttons: [[{
        text: app.translate('Share profile via :'),
        label: true
      }, {
        text: app.translate('Whatsapp'),
        onClick: function () {
          window.open('whatsapp://send?text=' + app.translate('Here is a link to the craftsman\'s profile. Take a look and see if they\'re a good fit for your project.') + ' : ' + link, '_blank');
          app.trackEvent('profile_shared');
        }
      }, {
        text: app.translate('Facebook'),
        onClick: function () {
          window.open('https://www.facebook.com/sharer/sharer.php??t=' + app.translate('Here is a link to the craftsman\'s profile. Take a look and see if they\'re a good fit for your project.') + '&u=' + link, '_blank');
          app.trackEvent('profile_shared');
        }
      }, {
        text: app.translate('Twitter'),
        onClick: function () {
          window.open('https://twitter.com/intent/tweet?url=' + link + '&text=' + app.translate('Here is a link to the craftsman\'s profile. Take a look and see if they\'re a good fit for your project.'), '_blank');
        }
      }, {
        text: app.translate('Copy Profile link'),
        onClick: function () {
          // Create a new textarea element (off-screen) to hold the text
          const textarea = document.createElement('textarea');
          textarea.value = link;

          // Append the textarea to the document
          document.body.appendChild(textarea);

          // Select the text in the textarea
          textarea.select();

          // Copy the selected text to the clipboard
          document.execCommand('copy');

          // Remove the textarea element from the document
          document.body.removeChild(textarea);

          // Provide feedback to the user
          app.ui.dialog.alert(app.translate('Profile link Copied!'));

          // track event
          app.trackEvent('profile_shared');
        }
      }], [{
        text: app.translate('Cancel'),
        color: 'red'
      }]]
    }).open();
  }

  /**
   * like profile
   */
  likeProfile(profileId, status) {
    let isLiked = typeof status == 'undefined' ? $(`[class*="craftsman-profile-${profileId}"]`).hasClass('liked') : status;

    // if this page is an iframe
    if ($('body').hasClass('iframe')) {
      window.parent.postMessage({
        type: 'like-craftsman-profile',
        user: {
          id: profileId
        },
        status: isLiked
      }, '*');
      return;
    }

    // remove like
    if (isLiked) {
      $(`[class*="craftsman-profile-${profileId}"]`).removeClass('liked');
      $(`[class*="craftsman-profile-${profileId}"] iframe`).each((idx, iframe) => {
        iframe.contentWindow.document.getElementsByClassName(`craftsman-profile-${profileId}`)[0].classList.remove('liked');
      });
      return app.sendHttpRequest({
        url: '/craftsmen/' + profileId + '/likes',
        method: 'DELETE'
      }).then(function () {
        app.trackEvent('profile_disliked');
      }).catch(function (error) {
        $(`[class*="craftsman-profile-${profileId}"]`).addClass('liked');
        $(`[class*="craftsman-profile-${profileId}"] iframe`).each((idx, iframe) => {
          iframe.contentWindow.document.getElementsByClassName(`craftsman-profile-${profileId}`)[0].classList.add('liked');
        });
        return app.ui.dialog.alert(app.translate(error.message));
      });
    }

    // like
    $(`[class*="craftsman-profile-${profileId}"]`).addClass('liked');
    $(`[class*="craftsman-profile-${profileId}"] iframe`).each((idx, iframe) => {
      iframe.contentWindow.document.getElementsByClassName(`craftsman-profile-${profileId}`)[0].classList.add('liked');
    });
    return app.sendHttpRequest({
      url: '/craftsmen/' + profileId + '/likes',
      method: 'POST'
    }).then(function () {
      app.trackEvent('profile_liked');
    }).catch(function (error) {
      $(`[class*="craftsman-profile-${profileId}"]`).removeClass('liked');
      $(`[class*="craftsman-profile-${profileId}"] iframe`).each((idx, iframe) => {
        iframe.contentWindow.document.getElementsByClassName(`craftsman-profile-${profileId}`)[0].classList.remove('liked');
      });
      return app.ui.dialog.alert(app.translate(error.message));
    });
  }

  /**
   * remove like profile
   */
  removeLikeProfile(profileId) {
    app.ui.dialog.confirm(app.translate('Are you sure you want to un-like this profile?'), app.translate('Un-like profile'), function () {
      const progress = app.ui.dialog.preloader(app.translate('Please wait ..'), -1);
      app.sendHttpRequest({
        url: '/craftsmen/' + profileId + '/likes',
        method: 'DELETE'
      }).then(function () {
        setTimeout(function () {
          progress.close();
          app.trackEvent('profile_disliked');
          app.ui.dialog.alert(app.translate('Your like for this profile has been canceled.'), function () {
            location.reload();
          });
        }, 2000);
      }).catch(function (error) {
        progress.close();
        app.ui.dialog.alert(app.translate(error.message));
      });
    });
  }

  /**
   * block profile
   */
  blockProfile(profileId) {
    // if this page is an iframe
    if ($('body').hasClass('iframe')) {
      window.parent.postMessage({
        type: 'block-craftsman-profile',
        user: {
          id: profileId
        }
      }, '*');
      return;
    }
    let el = $(`[class*="craftsman-profile-${profileId}"]`);
    app.ui.dialog.confirm(app.translate('Are you sure you want to block this profile?'), app.translate('Block profile'), function () {
      const progress = app.ui.dialog.preloader(app.translate('Please wait ..'), -1);
      el.each(function () {
        if ($(this).find('.btn-close-croftsman-profile').length) {
          $(this).hide();
          $(this).closest('.backdrop-in').hide();
          return;
        }
        if ($(this).parent().get(0).tagName.toLowerCase() == 'li') {
          $(this).parent().hide();
          return;
        }
        $(this).hide();
      });
      app.sendHttpRequest({
        url: '/craftsmen/' + profileId + '/dislikes',
        method: 'POST'
      }).then(function () {
        app.trackEvent('profile_blocked');
        el.each(function () {
          if ($(this).find('.btn-close-croftsman-profile').length) {
            $(this).find('.btn-close-croftsman-profile').trigger('click');
            return;
          }
          if ($(this).parent().get(0).tagName.toLowerCase() == 'li') {
            $(this).parent().remove();
            return;
          }
          $(this).remove();
        });
        setTimeout(function () {
          progress.close();
          app.ui.dialog.alert(app.translate('Thank you for your feedback. We have blocked the craftsman you requested. This means that you will no longer be able to see their profile.'), function () {
            location.reload();
          });
        }, 2000);
      }).catch(function (error) {
        progress.close();
        el.each(function () {
          if ($(this).find('.btn-close-croftsman-profile').length) {
            $(this).show();
            $(this).closest('.backdrop-in').show();
            return;
          }
          if ($(this).parent().get(0).tagName.toLowerCase() == 'li') {
            $(this).parent().show();
            return;
          }
          $(this).show();
        });
        app.ui.dialog.alert(app.translate(error.message));
      });
    });
  }

  /**
   * unBlock profile
   */
  unblockProfile(profileId) {
    // if this page is an iframe
    if ($('body').hasClass('iframe')) {
      window.parent.postMessage({
        type: 'unblock-craftsman-profile',
        user: {
          id: profileId
        }
      }, '*');
      return;
    }
    app.ui.dialog.confirm(app.translate('Are you sure you want to unblock this profile?'), app.translate('Unblock profile'), function () {
      const progress = app.ui.dialog.preloader(app.translate('Please wait ..'), -1);
      app.sendHttpRequest({
        url: '/craftsmen/' + profileId + '/dislikes',
        method: 'DELETE'
      }).then(function () {
        app.trackEvent('profile_unblocked');
        setTimeout(function () {
          progress.close();
          app.ui.dialog.alert(app.translate('We have unblocked the craftsman you requested.'), function () {
            location.reload();
          });
        }, 2000);
      }).catch(function (error) {
        progress.close();
        app.ui.dialog.alert(app.translate(error.message));
      });
    });
  }

  /**
   * open link
   */
  openSocialMediaLink(link) {
    // if this page is an iframe
    if ($('body').hasClass('iframe')) {
      window.parent.postMessage({
        type: 'open-link-social-network-craftsman-profile',
        link: link
      }, '*');
      return;
    }
    window.open(link, '_blank');
  }

  /**
   * get additional actions sheet
   */
  getAdditionalActionsSheet(profileId, profileLink) {
    // if this page is an iframe
    if ($('body').hasClass('iframe')) {
      window.parent.postMessage({
        type: 'craftsman-profile-additional-actions-sheet',
        user: {
          id: profileId,
          link: window.location.href.replace(window.location.search, '')
        }
      }, '*');
      return;
    }
    const myId = parseInt(user.id) || 0;
    const actions = [];
    if (profileLink) {
      actions.push({
        text: app.translate('Share profile'),
        bold: true,
        onClick: () => {
          setTimeout(() => {
            this.shareProfile(profileLink);
          }, 500);
        }
      });
    }
    if (myId != profileId) {
      actions.push({
        text: app.translate('Block profile'),
        bold: true,
        onClick: () => {
          this.blockProfile(profileId);
        }
      });
    }
    app.ui.actions.create({
      buttons: [actions, [{
        text: app.translate('Cancel'),
        color: 'red'
      }]]
    }).open();
  }
}
export default new craftsmanTools();